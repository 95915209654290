import { HttpClient } from "@angular/common/http";
import type { OnDestroy, Signal, WritableSignal } from "@angular/core";
import { Injectable, inject, signal } from "@angular/core";
import * as LaunchDarklyClient from "launchdarkly-js-client-sdk";
import { firstValueFrom } from "rxjs";
import type { LaunchDarklyContext } from "src/app/shared/services/launch-flags/launch-flags.models";
import { ManageUser } from "src/app/users/services/manageUser";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class LaunchFlagsService implements OnDestroy {
   private readonly http = inject(HttpClient);
   private readonly manageUser = inject(ManageUser);
   private readonly contextEndpoint = `${environment.flannelUrl}/launchFlags/context`;
   private client: LaunchDarklyClient.LDClient | undefined;
   private readonly flags: Map<string, WritableSignal<any>> = new Map();

   public ngOnDestroy(): void {
      /**
       * Close the client when the service is destroyed so that we don't leak resources and so it will report analytics
       * events to launchdarkly.
       */
      this.client?.close();
   }

   /**
    * @param flagName The name of the flag to get.
    * @param defaultValue The default value of the flag if it has not been set. This value is required as not all env support launch darkly.
    * In 21CFR flags are not allowed, so whatever default value is passed in will be used in those environments.  Your code should assume the
    * default value is the "off" state so that flagged code does not run in these environments.
    */
   public getFlag<T>(flagName: string, defaultValue: T): Signal<T> {
      let flag = this.flags.get(flagName);

      if (!flag) {
         const flagState = this.client?.variation(flagName, defaultValue);
         // It's possible to call getFlag() before the this.client has been set. In this case flagState will be undefined.
         flag = signal(flagState ?? defaultValue);
         this.flags.set(flagName, flag);
      }

      return flag.asReadonly();
   }

   /**
    * Background:
    *    Launch flags need to be setup as early in the application lifecycle as possible.  This helps
    *    mitigate potential issues with UI flicker caused by a launch flags default state being false leading to a
    *    feature being off until the launch flags service is initialized at which point the page rerenders.  This
    *    rerender can cause the UI to "flicker".
    *
    *    We pay for launch darkly based on usage. Because of this we don't want to connect to launch darkly on
    *    public facing pages or it could cause our costs to skyrocket.
    *
    * Why:
    *    Angular lazy loads its services. Without using this initialize function at the app root level the service would not
    *    get initialized until the first component that uses it is created. This effectively makes the initialization random
    *    as the first component to use it will change as flags are added and removed. Exposing an initialize method allows
    *    us to control when the service connects to launch darkly.
    *
    * WARNING:
    *    Launch flags should never be used on public facing pages. We are currently on a usage-based plan
    *    and a public facing page could cause our costs to skyrocket.
    *
    * @returns A promise that resolves when the LD client has been initialized.
    */
   public async initialize(): Promise<void> {
      // Don't re-initialize the LD client if one already exists
      if (this.client) {
         return;
      }

      if (this.isOnPublicRoute()) {
         return;
      }

      const context = await this.bootstrapContext();
      this.client = LaunchDarklyClient.initialize(
         environment.launchDarklyClientSideID,
         context,
         {
            streaming: true,
         },
      );

      this.client.on("change", (settings: LaunchDarklyClient.LDFlagChangeset) => {
         for (const key of Object.keys(settings)) {
            this.flags.get(key)?.set(settings[key].current);
         }
      });

      await this.client.waitForInitialization();
   }

   private async bootstrapContext(): Promise<LaunchDarklyContext> {
      try {
         return await firstValueFrom(
            this.http.get<LaunchDarklyContext>(this.contextEndpoint),
         );
      } catch (error) {
         console.error(new Error(`Failed to retrieve launch flags`));

         // Make sure the customer ID is available in the event of an error in the context endpoint
         await firstValueFrom(this.manageUser.currentUserChanges$);
         const customerID = this.manageUser.getCurrentUser()?.userInfo?.customerID;

         if (!customerID) {
            return {
               kind: "user",
               anonymous: true,
            };
         }

         return {
            kind: "customer",
            key: customerID,
         };
      }
   }

   /**
    * The list used her was determined by looking for routes with {requireLogin: false} in the routing setup.
    *
    * We use the window object to check the current URL because the Angular router is not yet initialized
    * when this code runs.
    *
    * @returns Whether or not the current page is a public route.
    */
   private isOnPublicRoute() {
      const publicURLs = [
         "/loc-problem",
         "/mobile-loc-problem",
         "/global-problem",
         "/mobile-global-problem",
         "/demoSwitchBoard",
         "/thanks",
         "/viewExternalTask",
         "/check-work-requests",
         "/wr",
         "/loggedInAsExternalUser",
      ];

      for (const url of publicURLs) {
         if (window.location.href.includes(url)) {
            return true;
         }
      }

      return false;
   }
}
